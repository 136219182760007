import React, { useState } from 'react';
import './QuickDonate.css';
import { Modal, Button } from 'react-bootstrap';
import DonationForm from '../DonationFormComponent/DonationForm';

const QuickDonate = () => {
    const [donationAmount, setDonationAmount] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedServiceId, setSelectedServiceId] = useState(null);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleAmountChange = (event) => {
        setDonationAmount(event.target.value);
    };

    const handleServiceClick = (serviceId) => {
        setSelectedServiceId(serviceId); // Set the selected service ID
        handleShow()
    };

    return (
        <div className="container-fluid home-quick-donate">
            <div className='main-quick my-4 container'>

                <p className='text-center body-paragraph color-white '>At Raza Foundation, we uphold the highest standards of integrity and transparency. We ensure that every penny of our donors' Zakat is dedicated solely to the intended causes and beneficiaries. None of your Zakat contributions are ever used to cover our operational expenses.</p>

                <div className='row mt-5 align-items-center gy-lg-0 gy-4'>
                    <div className='col-lg-6'>
                        <h3 className='head-h1 text-lg-start text-center color-white mb-4'>Contribute Today, Secure <span className='color-green'>Your Akhirah</span></h3>
                        <div className='input-donate-container d-flex  '>
                            <input
                                type="number"
                                className="form-control w-100 text-start"
                                id="donationAmount"
                                value={donationAmount}
                                onChange={handleAmountChange}
                                placeholder="Amount"
                            />
                            <button onClick={handleShow} className="connect-button justify-content-center d-flex mx-auto">
                            Donate
                            </button>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='row gy-3'>
                            <div className="col-lg-6 ">
                                    <button
                                        className="btn btn-outline-secondary w-100"
                                        onClick={() => handleServiceClick(7)}
                                    >
                                        Education
                                    </button>
                                </div>
                                <div className="col-lg-6">
                                    <button
                                        className="btn btn-outline-secondary w-100"
                                        onClick={() => handleServiceClick(27)}
                                    >
                                        Food
                                    </button>
                                </div>
                                <div className="col-lg-6">
                                    <button
                                        className="btn btn-outline-secondary w-100"
                                        onClick={() => handleServiceClick(29)}
                                    >
                                        Medical
                                    </button>
                                </div>
                                <div className="col-lg-6">
                                    <button
                                        className="btn btn-outline-secondary w-100"
                                        onClick={() => handleServiceClick(12)}
                                    >
                                        Sadaqa
                                    </button>
                                </div>
                        </div>
                    </div>

                </div>

                {/* <div className="button-container">
                    <div className="row mb-3">
                        <div className="col-lg col-md-0 mb-2">
                            <input
                                type="number"
                                className="form-control w-100 text-start"
                                id="donationAmount"
                                value={donationAmount}
                                onChange={handleAmountChange}
                                placeholder="Amount"
                            />
                        </div>

                        <div className="col">
                            <button
                                className="btn btn-outline-secondary w-100"
                                onClick={() => handleServiceClick(7)}
                            >
                                Education
                            </button>
                        </div>
                        <div className="col">
                            <button
                                className="btn btn-outline-secondary w-100"
                                onClick={() => handleServiceClick(27)}
                            >
                                Food
                            </button>
                        </div>
                        <div className="col">
                            <button
                                className="btn btn-outline-secondary w-100"
                                onClick={() => handleServiceClick(29)}
                            >
                                Medical
                            </button>
                        </div>
                        <div className="col">
                            <button
                                className="btn btn-outline-secondary w-100"
                                onClick={() => handleServiceClick(12)}
                            >
                                Sadaqa
                            </button>
                        </div>
                    </div>

                    <div className="col-auto">
                        <button onClick={handleShow} className="connect-button justify-content-center d-flex mx-auto">
                        Contribute Today, Secure Your Akhirah
                        </button>
                    </div>
                </div> */}
            </div>

            <Modal show={showModal} onHide={handleClose} size="xl" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <DonationForm hideTransform={true} hideTransformOne={false} donationAmount={donationAmount} defaultService={selectedServiceId} />
                </Modal.Body>
            </Modal>

        </div >
    );
};

export default QuickDonate;
