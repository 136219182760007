import Settings from "./Settings";
import SystemApiCallSettings from "./SystemApiCallSettings";
import { showAlertMessage } from "../MasterComponents/CommonFunctionalities";

export default class CommonCalls {

    /* GET API Calls */
    async getDocumentItems(userProps) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiBaseUrl + '/sample_get_api', userProps);
            if (res.status === 200) {
                return res.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    // get all subscribe user

    async getAllSubscribedUsers(page_size = 10, page_number = 1) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + '/get_all_subscribed_users', { token: userInfo?.token, page_size: page_size, page_number: page_number  }, { page_size: page_size, page_number: page_number });
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            showAlertMessage('Error fetching subscribed users:', 'fail');
            return [];
        }
    }

    //get services call

    async getServicesItems(page_size = 10, page_number = 1) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.enumTypes + '/get_all_enum_services', { page_size: page_size, page_number: page_number });
            console.log(res)
            if (res.status === 200) {
                return res.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    // get all modules

    async getModuleItems(page_size = 10, page_number = 1) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            const res = await SystemApiCallSettings('GET', Settings.enumTypes + `/get_all_enum_modules?page_number=${page_number}&page_size=${page_size}`, { token: userInfoToken });
            if (res.status === 200) {
                return res.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    // get all blogs 

    async getBlogsItems(page_number = 1, page_size = 10) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            const res = await SystemApiCallSettings('GET', Settings.apiBlogsUrl + `/get_all_blogs?page_number=${page_number}&page_size=${page_size}`, { token: userInfoToken });
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            showAlertMessage('Error fetching blogs:', 'fail');
            return [];
        }
    }

    // get all pending blogs

    async getPendingBlogs() {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('GET', Settings.apiBlogsUrl + '/get_all_pending_blogs', { token: userInfo?.token });
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            showAlertMessage('Error fetching blogs:', 'fail');
            return [];
        }
    }

    // get blog by Id

    async getBlogById(blogId) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('GET', `${Settings.apiBlogsUrl}/get_blog/${blogId}`, { token: userInfo?.token });
            if (res.status === 200) {
                return res.data;
            } else {
                console.error('Failed to fetch blog:', res);
                return null;
            }
        } catch (error) {
            console.error('Error fetching blog:', error);
            return null;
        }
    }

    // get blog by slug 

    async getBlogBySlug(blogId) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('GET', `${Settings.apiBlogsUrl}/get_blog_by_slug/${blogId}`, { token: userInfo?.token });
            if (res.status === 200) {
                return res.data;
            } else {
                console.error('Failed to fetch blog:', res);
                return null;
            }
        } catch (error) {
            console.error('Error fetching blog:', error);
            return null;
        }
    }

    // get all donation users

    async getAllDonationUsers(page_number = 1, page_size = 10) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/get_all_donation_users?page_number=${page_number}&page_size=${page_size}`, { token: userInfoToken });
            if (res.status === 200) {
                // showAlertMessage("Donation Users fetch Successfully", 'success')
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    // get all system user 

    async getAllSystemUsers(page_number = 1, page_size = 10) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('GET', `${Settings.apiUserUrl}/all_system_users?page_number=${page_number}&page_size=${page_size}`, { token: userInfo?.token });
            if (res.status === 200) {
                return res.data;
            } else {
                showAlertMessage('Failed to fetch system users:', 'fail');
                return [];
            }
        } catch (error) {
            showAlertMessage('Error fetching system users:', 'fail');
            return [];
        }
    }

    // get user details by ID

    async getUserDetails(user_Id) {
        try {
            console.log(user_Id)
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/get_system_user/${user_Id}`, { token: userInfo?.token }, true, {}, 'application/json', true);
            if (res?.status === 200) {
                return { response: res, token: userInfo?.token };
            } else {
                return [];
            }
        } catch (error) {
            showAlertMessage("Something went wrong", 'fail')
            return [];
        }
    };

    // Delete blog

    async deleteBlogItem(blogId) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('DELETE', `${Settings.apiBlogsUrl}/delete_blogs/${blogId}`, { token: userInfo?.token });
            showAlertMessage("Blog Deleted Successfully.", 'success')
            return res.status === 200;
        } catch (error) {
            console.error('Error deleting blog:', error);
            return false;
        }
    }

    // update blog

    async updateBlogItem(blogId, blogData) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('PUT', `${Settings.apiBlogsUrl}/update_blog/${blogId}`, { token: userInfo?.token }, false, blogData);
            showAlertMessage("Blog Updated Successfully", 'success')
            return res.status === 202;
        } catch (error) {
            showAlertMessage('Error updating blog:', 'fail');
            return false;
        }
    }

    // PUT Web Service

    async updateServiceItem(serviceId, serviceData) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings(
                'PUT',
                `${Settings.enumTypes}/update_enum_service/${serviceId}`,
                { token: userInfo?.token },
                false,
                serviceData
            );
            showAlertMessage("Service Updated Successfully", 'success');
            return res.status === 202;
        } catch (error) {
            showAlertMessage('Error updating service:', 'fail');
            return false;
        }
    }

    // update system user

    async updateSystemUser(userId, userData) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('PUT', `${Settings.apiUserUrl}/update_system_user/${userId}`, { token: userInfo?.token }, true, userData, 'application/json', true);
            if (res?.status === 202) {
                // showAlertMessage("User updated successfully", 'success')
                return { response: res, token: userInfo?.token };
            } else {
                return [];
            }
        } catch (error) {
            showAlertMessage("Something went wrong", 'fail')
            return [];
        }
    };


    /* GET By ID Calls */
    async getDocumentItemsById(userProps, document_id) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiBaseUrl + `/get_document_by_id/${document_id}`, userProps, false);
            if (res.status === 200) {
                return res.data;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

    async getAllQuestions(pageNumber=1, pageSize=10, getQuestions=false) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiQuestionsUrl + `/get_all_questions?get_answers=${getQuestions}&page_number=${pageNumber}&page_size=${pageSize}`, {}, false);
            if (res.status === 200) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

    /* PUT API Calls */
    async updateDocumentById(userProps, document_id, payload) {
        try {
            const res = await SystemApiCallSettings('PUT', Settings.apiBaseUrl + `/sample_update_api/${document_id}`, userProps, false, payload);
            return res;
        } catch (error) {
            return error;
        }
    };

    async answerAQuestion(questionId, answer) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('PUT', `${Settings.apiQuestionsUrl}/update_user_question/${questionId}`, { token: userInfo?.token }, true, answer, 'application/json', true);
            console.log(res, "res here")
            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            showAlertMessage("Something went wrong", 'fail')
            return [];
        }
    };

    /* POST API Calls */
    async addNewBlog(payload) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('POST', Settings.apiBlogsUrl + `/add_new_blog`, { token: userInfo?.token }, false, payload);
            showAlertMessage("New Blog Successfully Added", 'sucess')
            return res;
        } catch (error) {
            showAlertMessage("Error while uploading new blog", 'fail')
            return error;
        }
    };

    async addNewDonationUser(payload) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + `/new_donation_user`, {}, false, payload);

            if (res.status === 201) {
                return res;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            showAlertMessage("Something went wrong while donating", 'fail')
            return error;
        }
    };

    async sendQuestion(payload) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if(!userInfo)
        {
            showAlertMessage("Please Login/Signup to send a question","fail");
            return
        }
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiQuestionsUrl + `/ask_questions`, { token: userInfo?.token }, false, payload);
            if(res.status == 201)
            {
                showAlertMessage("Question sended successfully", 'success')
            }
            return res;
        } catch (error) {
            showAlertMessage("Error occurred while sending question","fail")
            return error;
        }
    };

    // get new subscribe user
    async addNewSubscriber(payload) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + `/subscribe_user`, {}, false, payload);
            showAlertMessage("NewsLetter Subscribed Successfully", 'sucess')
            return res;
        } catch (error) {
            showAlertMessage("Error occurred while Subscribing the NewsLetter")
            return error;
        }
    };

    // User api
    async registerUserToDB(userData) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + '/register-new-user', {}, true, userData);
            if (res.status === 201) {
                showAlertMessage("User registered Successfully", 'success')
                return res;
            } else {
                showAlertMessage("Something went wrong", "fail")
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    /* Delete API Calls */
    async removeDocument(userProps, document_id) {
        try {
            const res = await SystemApiCallSettings('DELETE', Settings.apiBaseUrl + '/delete_document/' + document_id, userProps, false);
            return res;
        } catch (error) {
            return error;
        }
    };

    // delete deleteSystemUser

    async deleteSystemUser(userId) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('DELETE', `${Settings.apiUserUrl}/delete_system_user/${userId}`, { token: userInfo?.token });
            showAlertMessage("System User Deleted Successfully.", 'success');
            return res.status === 200;
        } catch (error) {
            showAlertMessage('Error deleting system user:', 'fail');
            return false;
        }
    }

    //Send Contact Form
    async sendContactForm(payload) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + `/send-contact-form`, { }, false, payload);
            if(res?.status == 200)
            {
                showAlertMessage("Contact Form Sended Successfully", 'success')
                return res;
            }
            else
            {
                showAlertMessage("Error while sending Contact Form", 'fail')
                return [];
            }
        } catch (error) {
            showAlertMessage("Error while sending Contact Form", 'fail')
            return error;
        }
    };
    //Send FeedBack Form
    async sendFeedBackForm(payload) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + `/send-feedback-form`, { }, false, payload);
            if(res?.status == 200)
            {
                showAlertMessage("FeedBack Sended Successfully", 'success')
                return res;
            }
            else
            {
                showAlertMessage("Error while sending FeedBack", 'fail')
                return [];
            }
        } catch (error) {
            showAlertMessage("Error while sending FeedBack", 'fail')
            return error;
        }
    };
}


