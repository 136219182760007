import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../components/UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../components/CommonAPICalls/CommonCalls";
import { gridLinkValueFormatter, getDateFormatted } from '../components/MasterComponents/CommonFunctionalities';
import { useSelector } from 'react-redux';

const BlogsUserGridPage = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const apiCalls = new CommonCalls();
    const navigate = useNavigate();

    const userInfo = useSelector((state) => state.userLogin.userInfo);

    useEffect(() => {
        getAllBlogs();
    }, [pageNumber, pageSize]);

    const getAllBlogs = async () => {
        setLoading(true);
        try {
            let response;
            let totalRecordsFromApi

            if (userInfo?.is_admin) {
                response = await apiCalls.getBlogsItems(pageNumber, pageSize);
                totalRecordsFromApi = response?.total_records
                response= response?.data
            } else {
                response = await apiCalls.getPendingBlogs();
                totalRecordsFromApi = response?.total_records
                response= response?.data
            }

            if (response && Array.isArray(response)) {
                setUserData(response);
                setTotalRecords(totalRecordsFromApi || response.length);
            } else {
                console.error('Unexpected API response format:', response);
            }
        } catch (error) {
            console.error('Error fetching blogs:', error);
        } finally {
            setLoading(false);
        }
    };

    const onEmailClicked = (row, colKey) => {
        if (colKey === 'blog_content') {
            navigate(`/portal/all-blogs?editBlog=true&blogid=${row?.blog_id}`);
        }
    };

    const handleAddClick = () => {
        navigate('/portal/all-blogs?editBlog=true');
    };

    const gridColumnHeaders = [
        { field: 'blog_content', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'Content' },
        { field: 'blog_slug_url', columnName: 'Slug Url' },
        { field: 'createdAt', cellRenderer: getDateFormatted, customRendererLink: true, columnName: 'Created At' },
        { field: 'updatedAt', cellRenderer: getDateFormatted, customRendererLink: true, columnName: 'Updated At' },
    ];

    return (
        <div className='listings_view_wrapper' style={{ padding: "20px" }}>
            <h1 className="h1_main mt-2">All Blogs</h1>

            <CustomDataGrid
                columns={gridColumnHeaders}
                data={userData}
                pageSize={pageSize}
                loadingState={loading}
                pagination={true}
                onCellClicked={onEmailClicked}
                totalRecords={totalRecords}
                currentPage={pageNumber}
                onPageChange={setPageNumber}
                onPageSizeChange={setPageSize}
                onAddClick={handleAddClick}
                hideExcelBtn={false}
                excelFileBtnType={"Blogs"}
                excelFn={apiCalls.getBlogsItems}
                excelArgument={[1,totalRecords]}
            />
        </div>
    );
};

export default BlogsUserGridPage;
