import React, { useState, useEffect } from 'react';
import FieldsRenderer from "../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import './DonationForm.css';
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities';
import Settings from '../CommonAPICalls/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';


const DonationForm = ({ defaultService = '', hideTransform, hideTransformOne = true, donationAmount, }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [serviceOptions, setServiceOptions] = useState([]);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        cnic_number: '',
        donation_amount: donationAmount || '',
        service_type: defaultService,
        portions: 1,
        donation_type: '',
        transaction_from_account_title: '',
        transaction_from_account_number: ''
    });

    const copyToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        showAlertMessage("Copied to clipboard", "success")
    };

    const [fieldsArray, setFieldsArray] = useState([
        { inputType: 'text', label: 'First Name', name: 'first_name', placeholder: 'Type Your First Name', required: true },
        { inputType: 'text', label: 'Last Name', name: 'last_name', placeholder: 'Type Your Last Name', required: true },
        { inputType: 'text', label: 'Email Address', name: 'email', placeholder: 'Type Your Email Address', required: true },
        { inputType: 'text', label: 'Contact Number', name: 'phone_number', placeholder: 'Type Your Phone Number', required: true },
        { inputType: 'text', label: 'CNIC Number', name: 'cnic_number', placeholder: 'Type Your CNIC Number', required: true },
        { inputType: 'dropdown', label: 'Service', name: 'service_type', options: serviceOptions, required: true },
        { inputType: 'text', label: 'Donation Amount', name: 'donation_amount', placeholder: 'Enter Donation Amount', required: true },
        { inputType: 'text', label: 'Account Title', name: 'transaction_from_account_title', placeholder: 'Type Your Account Title', required: true },
        { inputType: 'text', label: 'Account Number', name: 'transaction_from_account_number', placeholder: 'Type Your Account Number', required: true },
    ]);

    const apiCall = new CommonCalls();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const selectedServiceId = query.get('service_id');

    const serviceList = useSelector((state) => state.serviceList);
    const { services, loading: serviceLoading, error: serviceError } = serviceList;

    useEffect(() => {
        setLoading(serviceLoading);
        if (serviceError) {
            setError(serviceError);
        }
    }, [serviceLoading, serviceError]);

    useEffect(() => {
        if (services && services.length > 0) {
            const tempAr = services
                .filter((service) => service.services_id !== 11 && service.services_id !== 14)
                .map((service) => ({
                    label: service.service_name,
                    value: service.services_id
                }));
            setServiceOptions(tempAr);
        }
    }, [services]);

    useEffect(() => {
        if (defaultService) {
            console.log(defaultService)
            setFormData((prevState) => ({
                ...prevState,
                service_type: defaultService,
            }));
        }
    }, [defaultService]);

    // Step 7: Use useEffect to update the formData when donationAmount changes
    useEffect(() => {
        if (donationAmount) {
            setFormData((prevState) => ({
                ...prevState,
                donation_amount: donationAmount  // Update the formData with the new donationAmount
            }));
        }
    }, [donationAmount]);

    // Handle input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if (formData.service_type === '6') {
            setFieldsArray([
                { inputType: 'text', label: 'First Name', name: 'first_name', placeholder: 'Type Your First Name', required: true },
                { inputType: 'text', label: 'Last Name', name: 'last_name', placeholder: 'Type Your Last Name', required: true },
                { inputType: 'text', label: 'Email Address', name: 'email', placeholder: 'Type Your Email Address', required: true },
                { inputType: 'text', label: 'Contact Number', name: 'phone_number', placeholder: 'Type Your Phone Number', required: true },
                { inputType: 'text', label: 'CNIC Number', name: 'cnic_number', placeholder: 'Type Your CNIC Number', required: true },
                { inputType: 'dropdown', label: 'Service', name: 'service_type', options: serviceOptions, required: true },
                { inputType: 'portions', label: 'Donation Amount', name: 'donation_amount', required: true },
                { inputType: 'dropdown', label: 'Donation Type', name: 'donation_type', options: Settings.enumDonationType, required: true },
                // { inputType: 'text', label: 'Donation Amount', name: 'donation_amount', placeholder: 'Enter Donation Amount', required: true, readOnly: true },
                { inputType: 'text', label: 'Account Title', name: 'transaction_from_account_title', placeholder: 'Type Your Account Title', required: true },
                { inputType: 'text', label: 'Account Number', name: 'transaction_from_account_number', placeholder: 'Type Your Account Number', required: true },
                { inputType: 'sacrifice', label: 'Names for Sacrifice Shares', name: 'sacrifice' }, // Added sacrifice field

            ]);
        } else {
            setFieldsArray([
                { inputType: 'text', label: 'First Name', name: 'first_name', placeholder: 'Type Your First Name', required: true },
                { inputType: 'text', label: 'Last Name', name: 'last_name', placeholder: 'Type Your Last Name', required: true },
                { inputType: 'text', label: 'Email Address', name: 'email', placeholder: 'Type Your Email Address', required: true },
                { inputType: 'text', label: 'Contact Number', name: 'phone_number', placeholder: 'Type Your Phone Number', required: true },
                { inputType: 'text', label: 'CNIC Number', name: 'cnic_number', placeholder: 'Type Your CNIC Number', required: true },
                { inputType: 'dropdown', label: 'Service', name: 'service_type', options: serviceOptions, required: true },
                { inputType: 'text', label: 'Donation Amount', name: 'donation_amount', placeholder: 'Enter Donation Amount', required: true },
                { inputType: 'text', label: 'Account Title', name: 'transaction_from_account_title', placeholder: 'Type Your Account Title', required: true },
                { inputType: 'text', label: 'Account Number', name: 'transaction_from_account_number', placeholder: 'Type Your Account Number', required: true },
            ]);
        }
    }, [formData.service_type, serviceOptions]);

    const validateForm = (inputsFieldAr, formInputData) => {
        let passStatus = true;

        // Validate required fields
        inputsFieldAr.forEach((input) => {
            if (((!formInputData[input.name]) && input.required) && passStatus) {
                showAlertMessage(`${input.label} field required`, "fail");
                passStatus = false;
            }
        });

        if (passStatus) {
            // Validate specific fields
            const accountNumberPattern = /^\d{10,12}$/;
            if (!accountNumberPattern.test(formData.transaction_from_account_number)) {
                showAlertMessage('Account Number must be between 10 and 12 digits.', 'fail');
                passStatus = false;
            }


            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.email)) {
                showAlertMessage('Please enter a valid email address.', 'fail');
                passStatus = false;
            }

            const phonePattern = /^\d{11}$/;
            if (!phonePattern.test(formData.phone_number)) {
                showAlertMessage('Phone Number must be 11 digits.', 'fail');
                passStatus = false;
            }
        }

        return passStatus;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        let validationResponse = validateForm(fieldsArray, formData);
        if (!validationResponse) {
            setLoading(false);
            return;
        }

        const filteredData = {};
        fieldsArray.forEach(field => {
            if (formData[field.name]) {
                filteredData[field.name] = formData[field.name];
            }
        });

        try {
            const response = await apiCall.addNewDonationUser({
                ...filteredData,
                service_type: filteredData?.service_type && serviceOptions?.find(val => val.value == filteredData?.service_type)?.label
            });

            if (response?.status == 201) {
                showAlertMessage(response?.message, 'success');

                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    cnic_number: '',
                    donation_amount: '',
                    service_type: '',
                    portions: 1,
                    donation_type: '',
                    transaction_from_account_title: '',
                    transaction_from_account_number: ''
                });
            } else {
                showAlertMessage(`Error: ${response.message}`, 'fail');
            }
        } catch (error) {
            showAlertMessage('An error occurred. Please try again later.', 'fail');
        } finally {
            setLoading(false);
        }
    };

    const bankDetails = [
        {
            icon: '/images/creatives/bank-alfalah.png',
            accountTitle: 'Muhammad Mohsin Khan',
            iban: 'PK35ALFH0216001008577623',
            branchName: 'Malir City Branch, Karachi',
            branchCode: '0216',
            accountNumber: '02161008577623',
            bankName: 'Bank Alfalah',
            bgColor: '#EAEAEA' // Example color for Bank Alfalah
        },
        {
            icon: '/images/creatives/raast.png',
            raastId: '03442440396',
            bankName: 'Raast'
        },
        {
            icon: '/images/creatives/konnect.png',
            accountTitle: 'Muhammad Mohsin Khan',
            iban: 'PK38 HABB 0164030075268718',
            accountNumber: '03442440396',
            bankName: 'Konnect by HBL',
            bgColor: '#00A859' // Example color for this bank
        },
        {
            icon: '/images/creatives/easypaisa.png',
            accountTitle: 'Muhammad Mohsin Khan',
            iban: 'PK44 TMFB 0000000043570199 ',
            accountNumber: '03442440396',
            bankName: 'Easypaisa'
        },
        {
            icon: '/images/creatives/jazz-cash.png',
            accountTitle: 'Muhammad Mohsin Khan',
            iban: 'PK20 JCMA 2208923442140396',
            accountNumber: '03442440396',
            bankName: 'JazzCash'
        },

    ];

    return (
        <div className="main-donation-container">
            <div className="container">
                <div className="Donation-form-container">
                    <h2 className="head-h1 text-center">Bank Account Details</h2>

                    {/* First row with 2 cards */}
                    <Row className={`g-4 ${hideTransformOne ? 'no-transform' : 'd-none'}`} >
                        {bankDetails.slice(0, 2).map((bank, index) => (
                            <Col md={6} lg={6} key={index}>
                                <Card className="h-100 card-body">
                                    <Card.Body className="d-flex align-items-center">
                                        <div className="Account-image-div">
                                            <img src={bank.icon} alt={bank.bankName} className="bank-logo" />
                                        </div>
                                        <div>
                                            <p className="mb-1 detail-text"><strong className="para_main">Account Title:</strong> {bank.accountTitle || 'N/A'}</p>
                                            <p className="mb-1 detail-text"><strong className="para_main">IBAN:</strong> {bank.iban || 'N/A'}</p>
                                            {bank.branchName && <p className="mb-1 detail-text"><strong className="para_main">Branch Name:</strong> {bank.branchName}</p>}
                                            {bank.branchCode && <p className="mb-1 detail-text"><strong className="para_main">Branch Code:</strong> {bank.branchCode}</p>}
                                            <p className="mb-1 detail-text"><strong className="para_main">Account Number:</strong> {bank.accountNumber || 'N/A'}</p>
                                            {bank.raastId && <p className="mb-1 detail-text"><strong className="para_main">Raast ID:</strong> {bank.raastId}</p>}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    {/* Second row with 3 cards */}
                    <Row className={`g-4 mt-2 ${hideTransformOne ? 'no-transform' : 'd-none'}`} >
                        {bankDetails.slice(2, 5).map((bank, index) => (
                            <Col md={4} lg={4} key={index}>
                                <Card className="h-100 card-body">
                                    <Card.Body className="d-flex align-items-center">
                                        <div className="Account-image-div">
                                            <img src={bank.icon} alt={bank.bankName} className="bank-logo" />
                                        </div>
                                        <div>
                                            <p className="mb-1 detail-text"><strong className="para_main">Account Title:</strong> {bank.accountTitle || 'N/A'}</p>
                                            <p className="mb-1 detail-text"><strong className="para_main">IBAN:</strong> {bank.iban || 'N/A'}</p>
                                            {bank.branchName && <p className="mb-1 detail-text"><strong className="para_main">Branch Name:</strong> {bank.branchName}</p>}
                                            {bank.branchCode && <p className="mb-1 detail-text"><strong className="para_main">Branch Code:</strong> {bank.branchCode}</p>}
                                            <p className="mb-1 detail-text"><strong className="para_main">Account Number:</strong> {bank.accountNumber || 'N/A'}</p>
                                            {bank.raastId && <p className="mb-1 detail-text"><strong className="para_main">Raast ID:</strong> {bank.raastId}</p>}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <div className="Donation-form-container-popup">
                        <Row className={`g-4 ${hideTransform ? 'no-transform' : 'd-none'}`} >
                            {bankDetails.map((bank, index) => (
                                <Col md={6} lg={6} key={index} className=''>
                                    <Card className="card-body-popup">
                                        <Card.Body className="d-flex align-items-center popup-card">
                                            <div className='Account-image-div'>

                                                <img src={bank.icon} alt={bank.bankName} className="bank-logo-popup" />
                                            </div>
                                            <div>
                                                <p className="mb-1 detail-text-popup"><strong className='para_main'>Account Title:</strong> {bank.accountTitle || 'N/A'}</p>
                                                <p className="mb-1 detail-text-popup"><strong className='para_main'>IBAN:</strong> {bank.iban || 'N/A'}</p>
                                                {bank.branchName && <p className="mb-1 detail-text-popup"><strong className='para_main'>Branch Name:</strong> {bank.branchName}</p>}
                                                {bank.branchCode && <p className="mb-1 detail-text-popup"><strong className='para_main'>Branch Code:</strong> {bank.branchCode}</p>}
                                                <p className="mb-1 detail-text-popup"><strong className='para_main'>Account Number:</strong> {bank.accountNumber || 'N/A'}</p>
                                                {bank.raastId && <p className="mb-1 detail-text-popup"><strong className='para_main'>Raast ID:</strong> {bank.raastId}</p>}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <form onSubmit={handleSubmit} className='mt-3'>

                        <FieldsRenderer
                            // fieldsArray={[
                            //     { inputType: 'text', label: 'First Name', name: 'first_name', placeholder: 'Type Your First Name', required: true },
                            //     { inputType: 'text', label: 'Last Name', name: 'last_name', placeholder: 'Type Your Last Name', required: true },
                            //     { inputType: 'text', label: 'Email Address', name: 'email', placeholder: 'Type Your Email Address', required: true },
                            //     { inputType: 'text', label: 'Contact Number', name: 'phone_number', placeholder: 'Type Your Phone Number', required: true },
                            //     { inputType: 'text', label: 'CNIC Number', name: 'cnic_number', placeholder: 'Type Your CNIC Number', required: true },
                            //     { inputType: 'dropdown', label: 'Service', name: 'service_type', options: serviceOptions, required: true },
                            //     { inputType: 'text', label: 'Donation Amount', name: 'donation_amount', placeholder: 'Enter Donation Amount', required: true },
                            //     { inputType: 'text', label: 'Account Title', name: 'transaction_from_account_title', placeholder: 'Type Your Account Title', required: true },
                            //     { inputType: 'text', label: 'Account Number', name: 'transaction_from_account_number', placeholder: 'Type Your Account Number', required: true },
                            // ]}
                            fieldsArray={fieldsArray}
                            updateState={setFormData}
                            currentState={formData}   // Pass formData as currentState
                        />

                        <div className='bottom-button'>
                            <button type='submit' className='connect-button' disabled={loading}>
                                {loading ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DonationForm;
