import React, { useState } from 'react';
import './Signup.css';
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import { useDispatch } from 'react-redux';
import { handleLoginSuccess } from "../../store/actions/userActions";
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities';
import FieldsRenderer from '../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent';
import { encryptPassword, validateForm } from '../UI Helpers/HelperFunctions/Helper';

const inputsFieldAr = [
  {
    label: "Full Name",
    className: "col-lg-12",
    inputType: "text",
    name: "full_name",
    id: "full_name",
    required: true
  },
  {
    label: "Email",
    className: "col-lg-12",
    inputType: "text",
    name: "email",
    id: "email",
    required: true
  },
  {
    label: "Password",
    className: "col-lg-12",
    inputType: "password",
    name: "password",
    id: "password",
    required: true
  },
];

const Signup = ({ closeModal, openLogin }) => {
  const [formInputData, setFormInputData] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [saveBtn, setSaveBtn] = useState(false)
  const dispatch = useDispatch()

  const apiCall = new CommonCalls();

  const registerUser = async () => {
    setLoading(true)
    const userData = {
      "email": formInputData.email,
      "password": encryptPassword(formInputData.password),
      "full_name": formInputData.full_name
    }
    let validationResponse = validateForm(inputsFieldAr, formInputData);
    if (!validationResponse) {
      setLoading(false)
      setSaveBtn(false)
      return
    }
    const response = await apiCall.registerUserToDB(userData);
    if (response?.status === 201) {
      setError(false)
      setSaveBtn(false)
      setLoading(false)
      showAlertMessage("User registered Successfully", "success")
      handleLoginSuccess(dispatch, response)
      return response
    }
    else {
      setLoading(false)
      setSaveBtn(false)
      showAlertMessage(response?.message, "fail")
      setError("Could not send data to server")
      return
    }
  }

  return (
    <div className="container">
      <div className={`row flex-row-reverse no-gutters`}>

        <div className="col-lg-6 create-accout-section">
          <div className="row">
            <div className="col-12">
              <div className=" img-modal text-end d-block d-lg-none">
                <img onClick={closeModal}
                  src="/images/icons/icon-cross.png"
                  alt="cross-icon"
                  class="icon-cross"
                />
              </div>
            </div>
            <div className="col-12">

              <div className="login-box">
                <h2 className='h1_main'>Create an account</h2>
                <p className='para_main'>Be a part of our Raza Foundation family!</p>
                {/* <div className="social-login">
                  <img src='/images/icons/login-page-fb-icon.svg'></img>
                  <img src='/images/icons/login-page-google-icon.svg'></img>
                  <img src='/images/icons/login-page-linkedln-icon.svg'></img>
                </div> */}
                <form onSubmit={(e) => {
                  e.preventDefault()
                  setSaveBtn(true)
                  registerUser();
                }}
                >
                  <FieldsRenderer
                    fieldsArray={inputsFieldAr}
                    updateState={setFormInputData}
                    currentState={formInputData}
                  />

                  {/* <div className="form-group">
              <input type="text" className="form-control" placeholder="Your Full Name" />
            </div>
            <div className="form-group">
              <input type="email" className="form-control" placeholder="Your Email" />
            </div>
            <div className="form-group">
              <input type="password" className="form-control" placeholder="Password" />
            </div> */}
                  <button type="submit" className="connect-button w-100" disabled={saveBtn} onClick={closeModal}>

                    <div><span> Sign Up </span> {saveBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 welcome-section d-none d-lg-flex">
          <div className="signup-box">
            <h2>Welcome Back</h2>
            <p>Login to start helping the ones in need!</p>
            <button className="btn btn-outline-light" onClick={openLogin}>Sign In</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup