// FeedbackForm.js
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import './FormfeedBack.css'
import CommonCalls from '../CommonAPICalls/CommonCalls';

const FeedbackForm = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rating, setRating] = useState(5);
    const [comments, setComments] = useState('');
    const [saveBtn, setSaveBtn] = useState(false);

        const apiCall = new CommonCalls();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaveBtn(true);
        const feedbackData = {
            name,
            email,
            rating,
            comments
        };

        const response = await apiCall.sendFeedBackForm(
            feedbackData
        );
        
        if (response?.status === 200) {
            setName('');
            setEmail('');
            setRating(5);
            setComments('');
            setSaveBtn(false);
            return response;
        } else {
            setSaveBtn(false);
            return;
        }
    };

    return (
        <div className='form-container' style={{ background: props.background, padding: props.padding }}>
            <Container className="container" >
                <Row className="justify-content-center">
                    <Col md={8}>
                        <h2 className="text-center head-h1">Give Us Your Feedback</h2>
                        <Form onSubmit={handleSubmit}>
                            {/* Name Field */}
                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    style={{borderRadius: "32px"}}
                                />
                            </Form.Group>

                            {/* Email Field */}
                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    style={{borderRadius: "32px"}}
                                />
                            </Form.Group>

                            {/* Rating Field */}
                            <Form.Group controlId="formRating" className="mb-3">
                                <Form.Label>Rating</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={rating}
                                    onChange={(e) => setRating(e.target.value)}
                                    style={{borderRadius: "32px"}}
                                >
                                    <option value="5">5 - Excellent</option>
                                    <option value="4">4 - Good</option>
                                    <option value="3">3 - Average</option>
                                    <option value="2">2 - Poor</option>
                                    <option value="1">1 - Terrible</option>
                                </Form.Control>
                            </Form.Group>

                            {/* Comments Field */}
                            <Form.Group controlId="formComments" className="mb-3">
                                <Form.Label>Comments</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Write your comments here"
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    required
                                    style={{borderRadius: "32px"}}
                                />
                            </Form.Group>

                            {/* Submit Button */}
                            <button type="submit" className="w-100 connect-button" disabled={saveBtn}>
                                Submit Feedback
                                 {
                                    saveBtn &&
                                    <Spinner className='ms-2' style={{color:"white",width:"20px",height:"20px"}}></Spinner>
                                }
                            </button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FeedbackForm;
