import React, { useEffect, useState } from "react";
import FieldsRenderer from "../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import Loader from "../UI Helpers/Simple Loading/Loader";
import { checkIfArrayIsEmpty, gridLinkValueFormatter, showAlertMessage } from "../MasterComponents/CommonFunctionalities";
import { validateForm } from "../UI Helpers/HelperFunctions/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import CommonCalls from "../CommonAPICalls/CommonCalls";

const apiCall = new CommonCalls();

const DetailViewSystemUser = () => {
    const [saveBtn, setSaveBtn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [courseOptions, setCourseOptions] = useState([])
    const [formInputData, setFormInputData] = useState("");

    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const userId = params.get("userid");

    const getUserDetails = async () => {
        const response = await apiCall.getUserDetails(userId);

        if (response) {

            setFormInputData({
                email: response.response.data?.email,
                full_name: response.response.data?.full_name,
                password: response.response.data?.password,
                modules_access: response.response.data?.modules_access,
            });
        }
        setLoading(false);
    };
    const getCourseOptions = async () => {
        const response = await apiCall.getModuleItems();
        if (response) {
            const courses = response
                .filter(course => ![2,3, 6, 7].includes(course?.module_id))
                .map(course => ({
                    value: course?.module_id,
                    label: `${course?.module_name}`
                }));
    
            setCourseOptions(courses);
        }
    };
    

    useEffect(() => {
        const getData = async () => {

            setLoading(true);
            await getCourseOptions();
            if (userId) {
                setLoading(true);
                getUserDetails();
            } else {
                showAlertMessage("Could not Find User", "fail");
                navigate("/portal");
            }
        }
        getData();
    }, [userId]);

    const inputsFieldAr = [
        {
            label: "Email",
            className: "col-lg-4",
            inputType: "text",
            name: "email",
            id: "email",
            required: true,
        },
        {
            label: "Full Name",
            className: "col-lg-4",
            inputType: "text",
            name: "full_name",
            id: "full_name",
            required: true,
        },
        {
            label: "Password",
            className: "col-lg-4",
            inputType: "password",
            name: "password",
            id: "password",
        },
        {
            label: "Modules",
            className: "col-lg-4",
            inputType: "multi_dropdown",
            name: "modules_access",
            id: "modules_access",
            options: courseOptions,
            defaultValues: formInputData?.modules_access ?? []
        },
    ];

    const updateUserData = async () => {
        setSaveBtn(true);
        let validationResponse = validateForm(inputsFieldAr, formInputData);
        if (!validationResponse) {
            setSaveBtn(false);
            return;
        }
        const response = await apiCall.updateSystemUser(userId, formInputData);
        
        if (response?.response?.status === 202) {
            setSaveBtn(false);
            showAlertMessage(response?.response?.message, "success");
            return response;
        } else {
            setSaveBtn(false);
            showAlertMessage(response?.response?.message, "fail");
            return;
        }
    };
    return (
        <>
            {loading ? (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "80vh" }}
                >
                    <Loader />
                </div>
            ) : (
                <div className="user-detail-view-container container-fluid ps-4 pe-4">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            setSaveBtn(true);
                            updateUserData();
                        }}
                    >
                        <div className="w-100 text-end ">
                            <button
                                type="submit"
                                className="connect-button w-fit p-3"
                                disabled={saveBtn}
                            >
                                <div>
                                    <span> Save </span>{" "}
                                    {saveBtn ? (
                                        <output
                                            className="spinner-border spinner-border-sm ms-1"
                                            aria-hidden="true"
                                        ></output>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </button>
                        </div>
                        <FieldsRenderer
                            fieldsArray={inputsFieldAr}
                            updateState={setFormInputData}
                            currentState={formInputData}
                        />
                    </form>
                </div>
            )}
        </>
    );
};

export default DetailViewSystemUser; 