import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const RIMSPage = () => {
    return (
        <div>
            <CommonHeroComponent
                Heading="RIMS (Raza Institute of Islamic & Modern Studies)"
                Para="RIMS is dedicated to blending the teachings of Islam with modern education, offering a
comprehensive curriculum that nurtures both religious knowledge and worldly expertise.
"
                backgroundImg="/images/creatives/rims-banner.webp" />

            <VisionAndMission heading="About" bluespan=" RIMS (Raza Institute of Islamic & Modern Studies)" Para="The Raza Institute of Islamic & Modern Studies (RIMS) is a visionary educational
initiative that integrates Islamic teachings with contemporary education. RIMS focuses
on a holistic approach to learning, where students are not only well-versed in the Quran,
Hadith, and Islamic jurisprudence but are also equipped with modern academic
knowledge in science, technology, and social sciences. This blend of education ensures
that students are capable of leading successful lives in both religious and worldly
matters, staying true to Islamic values while navigating modern challenges. At RIMS, we
believe that knowledge is the key to empowerment, and through this unique educational
model, we are creating leaders for tomorrow." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
             
                image="/images/creatives/rims-one.jpg"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

            <VisionAndMission flexdirection="row-reverse" heading="Support RIMS Through" bluespan=" Your Donations" Para="Your donations to RIMS help us build a future where Islamic and modern education go
hand-in-hand. By contributing, you are ensuring that young Muslims receive a balanced
education that prepares them to excel in both religious and secular fields. The Prophet
Muhammad (PBUH) emphasized the importance of seeking knowledge, and your
donation supports this noble cause. Whether it's providing scholarships, improving
facilities, or supporting our staff, your generosity directly impacts the future of our youth
and strengthens their faith along with their worldly skills."
             
                image="/images/creatives/rims-two.webp" />

            <VisionAndMission heading="Easy Ways to Support and" bluespan=" Transform Lives" Para="Supporting RIMS is not only an act of charity but a meaningful contribution to the future
of the Muslim community. Your help makes Islamic education more accessible and
prepares students to take on the challenges of modern society without compromising
their faith. Through your contributions, you empower the next generation of Muslims to
lead with confidence, knowledge, and a deep-rooted understanding of their religion. As
the Prophet Muhammad (PBUH) said, “The best among you are those who learn and
teach others.” Your support of RIMS brings blessings to both your life and the lives of
many students." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
           
                image="/images/creatives/rims-three.webp" />

            <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
        </div>
    )
}

export default RIMSPage;