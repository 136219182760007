import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../components/UI Helpers/DataGrid/CustomDataGrid";
import Settings from "../components/CommonAPICalls/Settings";
import CommonCalls from "../components/CommonAPICalls/CommonCalls";
import { gridLinkValueFormatter, showAlertMessage, checkIfArrayIsEmpty } from '../components/MasterComponents/CommonFunctionalities';

const SubscribedUsersListings = () => {
  const [userData, setUserData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const apiCalls = new CommonCalls();

  const navigate = useNavigate();

  useEffect(() => {
    getAllUsers();
  }, [pageNumber, pageSize]);

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const apiResponse = await apiCalls.getAllSubscribedUsers(pageSize,pageNumber );
      if (apiResponse) {
        setUserData(apiResponse?.data);
        setTotalRecords(apiResponse?.total_records);
        setLoading(false);
      }
    } catch (err) {
      setUserData([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  

  const onEmailClicked = (row, colKey) => {
    if (colKey === 'email') {
      navigate(`/portal/system-users?userid=${row?.user_id}`);
    }
  };

  const onDeleteSystemUsers = async (rowItems) => {
    if (checkIfArrayIsEmpty(rowItems)) {
      showAlertMessage('Please select any rows to delete!', 'fail');
      return;
    };

    setLoading(true);

    for (const item of rowItems) {
      try {
        const apiResponse = await apiCalls.removeSystemUser(item?.user_id);
        if (apiResponse) {
          showAlertMessage(apiResponse?.message, 'success');
        }
      } catch (error) {
        showAlertMessage(error, 'fail');
      }
    };

    getAllUsers();
  };

  const gridColumnHeaders = [
    { field: 'user_email', columnName: 'Email' }

  ];

  return (
    <div className='listings_view_wrapper' style={{ padding: "20px" }}>
      <h1 className="h1_main mt-2">All Subscribed Users</h1>

      <CustomDataGrid
        columns={gridColumnHeaders}
        data={userData}
        pageSize={pageSize}
        onSelectedRowsDelete={onDeleteSystemUsers}
        loadingState={loading}
        hideAddBtn={true}
        pagination={true}
        onCellClicked={onEmailClicked}
        totalRecords={totalRecords}
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={setPageSize}
        searchField={true}
        hideDeleteBtn={true}
        hideExcelBtn={false}
        excelFileBtnType={"SubscribeUsers"}
        excelFn={apiCalls.getAllSubscribedUsers}
        excelArgument={[totalRecords,1]}
      />
    </div>
  );
};

export default SubscribedUsersListings; 