import Settings from "../../components/CommonAPICalls/Settings";
import SystemApiCallSettings from "../../components/CommonAPICalls/SystemApiCallSettings";
import { showAlertMessage } from "../../components/MasterComponents/CommonFunctionalities";
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';


const handleLoginSuccess = (dispatch, loginResponseData = [], showMessage = true, userResponseData = [], moduleResponseData = []) => {
  if (loginResponseData.status === 200) {
    showAlertMessage("User Logged In Successfully", "success");
  } else {
    // showAlertMessage("Something went wrong", "fail");
  }

  const userDetails = loginResponseData?.user_details;
  const payload = {
    user_id: userDetails?.user_id,
    is_admin: userDetails?.is_admin,
    full_name: userDetails?.full_name,
    token: loginResponseData?.token,
    email: userDetails?.email,
    modules_access: userDetails?.modules_access
  };


  if (!userDetails.is_admin && (!userDetails.modules_access || userDetails.modules_access.length === 0)) {
    showAlertMessage("You have logged in successfully but have no access to any modules.", 'success');
  }

  dispatch({
    type: "USER_LOGIN_SUCCESS",
    payload,
  });

  localStorage.setItem("userInfo", JSON.stringify({
    user_id: payload.user_id,
    token: payload.token,
    full_name: payload.full_name,
    email: payload.email,
    is_admin: payload.is_admin,
    modules_access: payload.modules_access
  }));
};

export const fetchUserDetails = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "USER_LOGIN_FETCH",
      });

      const userInfoFromStorage = JSON.parse(localStorage.getItem("userInfo"));
      const response = await new CommonCalls().getUserDetails(userInfoFromStorage.user_id);
      const responseData = response?.response

      console.log(responseData)

      if (responseData?.status === 200) {

        handleLoginSuccess(dispatch, {
          token: response?.token,
          user_details: { ...responseData?.data }
        }, false);
      }

      else {
        localStorage.removeItem("userInfo")
      }

    } catch (error) {

      localStorage.removeItem("userInfo")
      dispatch({
        type: "USER_LOGIN_FAIL",
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };
};

export const login = (userLogin) => {
   return async (dispatch) => {
    try {
      dispatch({
        type: "USER_LOGIN_REQUEST",
      });

      const loginResponseData = await SystemApiCallSettings('POST', Settings.apiUserUrl + '/login', {}, true, userLogin);

      // if (loginResponseData.user_id === 3) {
      //   const moduleResponseData = await SystemApiCallSettings('GET', Settings.apiBaseUrl + '/get_api_document');
      //   const userResponseData = await SystemApiCallSettings('GET', Settings.apiBaseUrl + '/', {
      //     user_type_id: loginResponseData.user_type_id,
      //     user_id: loginResponseData.user_id,
      //     token: loginResponseData.token,
      //   });

      handleLoginSuccess(dispatch, loginResponseData);
      // } else {
      //   handleLoginSuccess(dispatch, loginResponseData);
      // }
    } catch (error) {
      dispatch({
        type: "USER_LOGIN_FAIL",
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("userInfo");
    dispatch({ type: "USER_LOGOUT" });
    dispatch({ type: "USER_DETAILS_RESET" });
  };
};

export { handleLoginSuccess };
