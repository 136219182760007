import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import HomePage from "./screens/HomePage";
import Navbar from "./components/Header/Navbar";
import FooterNew from "./components/FooterNew/Footer"
import ContactPage from './components/ContactUsPage/ContactPage';
import Blogs from "./screens/Blogs";
import AboutPage from "./screens/AboutPage";
import ZakatPage from "./screens/ZakatPage";
import MehfilPage from "./screens/MehfilPage";
import HajjPage from "./screens/HajjPage";
import GoogleAds from "./screens/GoogleAds";
import GalleryPage from "./screens/GalleryPage";
import DonationPage from "./screens/DonationPage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import FitrahPage from "./screens/FitrahPage";
import NafilPage from "./screens/NafilPage";
import QurbaniPage from "./screens/QurbaniPage";
import EducationPage from "./screens/EducationPage";
import GlobalReliefPage from "./screens/GlobalReliefPage";
import MosquePage from "./screens/MosquePage";
import SadaqaPage from "./screens/SadaqaPage";
import COSPage from "./screens/COSPage";
import UtilitiesPage from "./screens/UtilitiesPage";
import GroceryPage from "./screens/GroceryPage";
import EducationFeesPage from "./screens/EducationFeesPage";
import WeddingPage from "./screens/WeddingPage";
import BusinessPage from "./screens/BusinessPage";
import RashanPage from "./screens/RashanPage";
import BoxPage from "./screens/BoxPage";
import SadatPage from "./screens/SadaPaget";
import TrustExpensesPage from "./screens/TrustExpensesPage";
import KhidmateKhalqPage from "./screens/Khidmat-e-Khalq-Page";
import HeaderSliderWraper from "./components/Raza-Portal/HeaderSliderWraper/HeaderSliderWraper";
import CommonHeroComponent from "./components/CommonHeroComponent/CommonHeroComponent";
import PrivacyPolicyPage from "./screens/PrivacyPolicyPage";
import RefundPolicyPage from "./screens/RefundPolicyPage";
import { fetchUserDetails, logout } from "./store/actions/userActions";
import DonationUserGridPage from "./screens/DonationUserGridPage";
import SystemExamsDetailView from '../src/components/PortalGrids/SystemExams/SystemExamsDetailView'
import SubscribedUserGridPage from "./screens/SubscribedUserGridPage";
import ServicesUserGridPage from "./screens/ServicesUserGridPage";
import BlogsEditor from "./components/BlogsEditor/BlogsEditor";
import BlogsUserGridPage from "./screens/BlogsUserGridPage";
import SystemUserGridPage from "./screens/SystemUserGridPage";
import PendingBlogsGridPage from "./screens/PendingBlogsGridPage";
import KhatmeNabowatPage from "./screens/KhatmeNabowatPage";
import BlogInner from "./components/BlogInner/BlogInner";
import BlogInnerPage from "./screens/BlogInnerPage";
import { listServices } from "./store/actions/serviceActions";
import DetailViewSystemUser from "./components/UserForm/DetailViewSystemUser";
import RazaLibraryPage from "./screens/RazaLibraryPage";
import NoorEducationPage from "./screens/NoorEducationPage";
import DarulIfftaPage from "./screens/DarulIfftaPage";
import RIMSPage from "./screens/RIMSPage";
import BalaghPage from "./screens/BalaghPage";
import EventsPage from "./screens/EventsPage";
import EventsInnerPage from "./screens/EventsInnerPage";
import VideosPage from "./screens/VideosPage";
import GuardianPage from "./screens/GuardianPage";
import FinancialAid from "./screens/FinancialAidPage";
import FinancialAidPage from "./screens/FinancialAidPage";
import PlantationPage from "./screens/PlantationPage";
import MasjidAndMadarsaConstruction from "./screens/MasjidAndMadarsaConstruction";
import CoronaRelief from "./screens/CoronaReliefPage";
import TharParkarPage from "./screens/TharParkarPage";
import BalochistanPage from "./screens/BalochistanPage";
import DastarkhowaanTharparkarPage from "./screens/DastarkhowaanTharparkarPage";
import RashanDistributePage from "./screens/RashanDistributePage";
import FamilySupportPage from "./screens/FamilySupportPage";
import ChildrenOrientationPage from "./screens/ChildrenOrientationPage";
import IslamicHistroyPage from "./screens/IslamicHistroyPage";
import QuranEducationPage from "./screens/QuranEducationPage";
import MedicinePage from "./screens/MedicinePage";
import OPDPage from "./screens/OPDPage";
import ConsultationPhysician from "./screens/ConsultationPhysician";
import ConsultationSpecialistPage from "./screens/ConsultationSpecialistPage";
import KhanaDelhiPage from "./screens/KhanaDelhiPage";
import LangurKhana from "./screens/LangurKhana";
import GenralServicesInnerPage from "./screens/GenralServicesInnerPage";
import AskAMufti from "./screens/AskAMufti";
import AskAMuftiGridPage from "./screens/AskAMuftiGridPage";
import AskaMuftiAnswerPage from "./screens/AskaMuftiAnswerPage";
import HeaderSliderWrapper from "./components/Raza-Portal/HeaderSliderWraper/HeaderSliderWraper";

const servicesPageData = [
  {
    url:"school-fees",
    heading:"School Fees",
    sectionImage:"/images/creatives/service-School-fees-content-image.png",
    para:"Millions of children are deprived of education because of high fees. Their dreams are unfulfilled as the burden of school fees forces the needy to forgo education. Either they can eat food or afford their education. Raza Foundation believes that education is for all and that every child deserves the right to learn and grow in their career, regardless of financial burden. With your support, we can provide free education to those who have a dream to do something and change their lives. With your donation support, we can make education accessible for all. By contributing to school fees, you are not just supporting their future but also investing in your Akhirah. ",
    ayah:"Who is it that would loan Allah a goodly loan so He may multiply it for him many times over? And it is Allah who withholds and grants abundance, and to Him, you will be returned. (Surat Al-Baqarah 2:245)",
     
  },
  {
    url:"dues",
    heading:"Dues",
    sectionImage:"/images/creatives/service-dues-content-image.png",
    para:"Giving is an act of kindness, and when it comes to donation, it brings hope to those in need. Donations have the power to uplift lower communities. Many students in our society struggle to pay their fees with dues, and unfortunately, their dreams of a brighter future are hindered by the financial crisis. Raza Foundation gives them hope for a bright future and better education and breaks the chain of poverty. Your small donations mean a lot and help us to cover their school dues and high tuition expenses, especially for underprivileged students.",
    ayah:"The example of those who spend their wealth in the way of Allah is like a seed [of grain] which grows seven spikes; in each spike is a hundred grains. And Allah multiplies [His reward] for whom He wills. And Allah is all-Encompassing and Knowing. (Surat Al-Baqarah 2:261)",
     
  },
  {
    url:"disaster-aid",
    heading:"Disaster Aid",
    sectionImage:"/images/creatives/service-disaster-aid-content-image.jpg",
    para:"In our society, many individuals struggle to earn a halal income. These are the people (Safaid posh) who maintain their dignity and hesitate to ask for help. But it is our moral and social responsibility to check others and ensure they have access to basic needs such as food, education, home, and clothes. At Raza Foundation, we believe in the power of efforts that bring positive changes in someone’s life. We actively participate in disaster relief such as floods, earthquakes, and common diseases. In such emergencies, our team steps forward with basic amenities like food, clothes, and shelter. ",
    ayah:"O you who have believed, spend from that which We have provided for you before there comes a Day in which there is no exchange and no friendship and no intercession. And the disbelievers - they are the wrongdoers. (Surat Al-Baqarah 2:254) ",
     
  },
  {
    url:"sindh-flood",
    heading:"Sindh Flood",
    sectionImage:"/images/creatives/service-sindh-food-content-image.png",
    para:"Floods leave behind unimaginable struggles for everyone. Since 2010, Pakistan has faced devastating floods. It affects millions of lives, including livelihoods and dreams. Raza Foundation is a trusted foundation providing food, shelter, and medical aid to rebuild their lives. In 2022, the Sindh flood was the worst in history. Thousands of people lost their lives, homes, and even crops. In every flood crisis, we ensure to provide relief where it is needed most. We aim to bring happiness, comfort and care so that no one feels abandoned. Your small act of kindness and generous donation can save thousands of lives.",
    ayah:"And establish prayer and give zakah, and whatever good you put forward for yourselves - you will find it with Allah. Indeed, Allah of what you do is Seeing. (Surah Al-Baqarah Ayat 110)",
     
  },
  {
    url:"palestine",
    heading:"Palestine",
    sectionImage:"/images/creatives/service-palestine-content-image.png",
    para:"We cannot forget the immense loss they have suffered in the last few years. But watching them crying and seeing their suffering doesn’t show true concern. We as human beings need to stand with them and support them in fulfilling their needs and dreams. Millions of innocent people have lost their lives, houses have been destroyed, and countless families have been left without shelter. Hospitals, businesses, schools, and places of worship have been attacked. It creates a severe humanitarian crisis for all of them. Children, adults, and the elderly cry out in hunger and pain, but their plea is ignored. At Raza Foundation, we provide urgent relief to the people of Palestine and provide support in medical aid, food, and shelter.",
    ayah:"For indeed, with hardship [will be] ease. Indeed, with hardship [will be] ease. Surah Ash-Sharh (94:5-6)",
     
  },
  {
    url:"earthquake-food-drive",
    heading:"Earthquake Food Drive",
    sectionImage:"/images/creatives/service-efd-content-image.png",
    para:"When it comes to supporting people, donations can be a lifeline for those affected. Every contribution in a hard time can make a real difference. Raza Foundation provides essential supplies like food, clean water, and medical aid through earthquake food drives. Children, adults, seniors, and vulnerable individuals are at higher risk of malnutrition after an earthquake. We deliver high-energy foods for babies and fortified meals to keep them healthy. Let’s come together and support the people in need.",
    ayah:"And if someone is in hardship, then [let there be] postponement until [a time of] ease. But if you give [from your right as] charity, then it is better for you if you only knew. Surah Al-Baqarah Ayat 280",
     
  },
  {
    url:"mastung-bomb-attack",
    heading:"Mastung Bomb Attack",
    sectionImage:"/images/creatives/service-mba-content-image.png",
    para:"The Mastung bomb attack has recently impacted thousands of families. This tragic incident reminds us of the fragility of life and happiness. In this difficult time, we need to stand together in a hard time. Victims out there are in desperate need of medical assistance, meals, and shelter, and it is our social responsibility to show some respect and extend a helping hand. Raza Foundation can provide quick relief to those suffering, and it is impossible without your donations. Your small participation means a lot to them, rebuilding hope in many lives.",
    ayah:"And be patient, for indeed, Allah does not allow to lose the reward of those who do good. Surah Hud Ayat 115",
     
  },
  {
    url:"food",
    heading:"Food",
    sectionImage:"/images/creatives/service-food-content-image.jpg",
    para:"Every day, millions of people struggle to find clean food for their families, and it is our responsibility to extend a helping hand. Through our food distribution support, we provide nutritious meals to the needy, widows, orphans, and the less fortunate in our society. At Raza Foundation, we are committed to fighting hunger and ensuring that no one goes to sleep on an empty stomach. Hunger is a harsh reality in our society, but together, as a powerful nation, we can make a real difference. Your generous donation can bring thousands of smiles on their faces.  ",
    ayah:'They ask you, [O Muhammad], what they should spend. Say, "Whatever you spend on goods is [to be] for parents and relatives and orphans and the needy and the traveller. And whatever you do of good - indeed, Allah is Knowing of it Surah Al-Baqarah Ayat 215',
     
  },
  {
    url:"ramzan-rashan-drive",
    heading:"Ramzan Rashan Drive",
    sectionImage:"/images/creatives/service-rrd-content-image.png",
    para:"At Raza Foundation, we are dedicated to serving humanity and supporting our community. This Ramzan, we continue our mission and support underprivileged families through the Ramzan Rashan Drive program. We encourage you all to participate in our mission and earn good deeds. Inflation is at its peak, and countless families struggle to afford even necessities. With your generous donations, we can provide essential food packages to those in need. Each packet includes 5kg flour, 5kg rice, lentils, salt, sugar, oil, and other staples to sustain a family for the month. Let’s come together and help our sisters and brothers in this blessed month.",
    ayah:"The example of those who spend their wealth in the way of Allah is like a seed [of grain] which grows seven spikes; in each spike is a hundred grains. And Allah multiplies [His reward] for whom He wills. And Allah is all-Encompassing and Knowing. Surah Al-Baqarah 2:261",
     
  },
  {
    url:"health",
    heading:"Health",
    sectionImage:"/images/creatives/service-health-content-image.jpg",
    para:"Good health is a blessing for all human beings that we may take for granted sometimes. But there are uncountable underprivileged, and access to basic healthcare remains a distant dream. Illness and disease do not discriminate, yet poverty forces many to suffer in silence. In this difficult time, they are unable to afford medical treatment. At Raza Foundation, we make sure that no one should be denied the right to health due to financial constraints. Your small donation can bring happiness to someone’s life. ",
    ayah:"Charitable men and charitable women who have loaned Allah a good loan—it will be multiplied for them, and for them is a generous reward. (Surah al-Hadid, 18)",
     
  },

  {
    url:"medical-aids",
    heading:"Medical Aids",
    sectionImage:"/images/creatives/service-medical-aids-content-image.png",
    para:"Many individuals, including adults, kids, and seniors, suffer from life-threatening diseases. These diseases spread quickly. It is simply because they cannot afford expensive treatment. At Raza Foundation, we ensure that no one should be deprived of expensive medical treatment due to financial hardship. Your small donation can cure someone, so join us in this noble cause by donating medical aid for poor people. Your participation can provide medicines, life-saving treatments, and essential healthcare necessities to them. Donate now and make a significant impact and bring hope to families in distress.",
    ayah:"And He has made me blessed wherever I am and has enjoined upon me Prayer and Zakah as long as I remain alive. (Surah Maryam 19:31)",
     
  },
  {
    url:"utility-bills",
    heading:"Utility Bills",
    sectionImage:"/images/creatives/service-utility-bills-content-image.png",
    para:"The burden of unpaid utility bills weighs heavily on thousands of families. This forces them to choose between food, treatments, medicine, or electricity. At Raza Foundation, we believe that if Allah has given you wealth, then don’t hesitate to help and support others. Just imagine that one lives in darkness or endure the harsh cold without heating. Nobody can survive in this life, so how can they? Support now, as your donation can bring light, warmth, and comfort to those who are in difficulty. ",
    ayah:"O believers! Donate from the best of what you have earned and of what We have produced for you from the earth. Do not pick out worthless things for donation, which you yourselves would only accept with closed eyes. And know that Allah is Self-Sufficient and praiseworthy. (Surah al baqarah 2:267)",
     
  },
  {
    url:"house-construction",
    heading:"House Construction",
    sectionImage:"/images/creatives/service-house-construction-content-image.png",
    para:"Many people in our community struggle day and night to build a house. Unfortunately, they failed to fulfil their dream due to low income. A secure home protects families from harsh weather. Everyone ensures that their family feel secure and stays in their comfort zone.  Unfortunately, many underprivileged families live in dire conditions, and they struggle to find shelter. A home is a basic human, and donating to build houses for the needy gives them security and a new beginning. At Raza Foundation, we ensure that everyone has a shelter, so your small donation gives a whole new life to someone.",
    ayah:"Zakah expenditures are only for the poor and for the needy and for those employed to collect [zakah] and for bringing hearts together [for Islam] and for freeing captives [or slaves] and for those in debt and the cause of Allah and the [stranded] traveller - an obligation [imposed] by Allah. And Allah is Knowing and Wise. (Surah At-Taubah Ayat 60)",
     
  },
  {
    url:"wedding",
    heading:"Wedding",
    sectionImage:"/images/creatives/service-wedding-content-image.png",
    para:"Just like us, underprivileged families also have a dream of seeing their daughters and sons getting married with dignity, ease, and respect. Unfortunately, financial constraints make it impossible for them. In such situations, the Raza Foundation plays a crucial role in their lives. We ensure that the brides receive all the necessary arrangements, such as dowry, wedding attire, and venue costs. This support gives ease to many families. With your small donation, we can remove the financial burden from struggling parents and brothers and make weddings a joyous occasion for the needy as well.",
    ayah:"The example of those who spend their wealth in the cause of Allah is that of a grain that sprouts into seven ears, each bearing one hundred grains. And Allah multiplies ˹the reward even more˺ to whoever He wills. For Allah is All-Bountiful, All-Knowing (Surah Al Baqarah 2:261)",
     
  },
  {
    url:"business-startup",
    heading:"Business Startup",
    sectionImage:"/images/creatives/service-business-startup-content-image.png",
    para:"The life of unprivileged people with less income is often filled with difficulties and struggles. They work really hard all day but still find it difficult to fulfil their basic needs. Many of them dream of starting a small business, such as a handmade jewellery stall, food stall, kiosk, and stitching services. Your support helps them to improve their financial situation. Unfortunately, lack of funds and guidance hold them back. Without your donations, these individuals remain stuck in a cycle of poverty and unable to improve their economic stability. Whether small or big, your support means a lot to someone.",
    ayah:"And establish prayer and give zakah, and whatever good you put forward for yourselves – you will find it with Allah. Indeed, Allah of what you do is Seeing. Surah Al-Baqarah Ayat 110",
     
  },
]

const App = () => {
  const [checkStatus, setCheckStatus] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const checkTokenStatus = async () => {
      dispatch(fetchUserDetails());
    }

    if (!checkStatus) {
      if (JSON.parse(localStorage.getItem("userInfo"))?.user_id) {
        checkTokenStatus()

      }
      else {
        localStorage.removeItem("userInfo");

      }
      setCheckStatus(true)
      dispatch(listServices());
      console.log("Hello string")
    }

  }, [])

  const ComonWrapperComponents = ({ children }) => {
    return (
      <>
        <Navbar />
        {children}
        <FooterNew />
      </>
    );
  };

  const AskAMuftiWrapper = ()=>{
          const location = useLocation();
          const params = new URLSearchParams(location.search);
          const answer = params.get("answer");
          return (
                <HeaderSliderWrapper>
                    {
                        answer ?
                        <AskaMuftiAnswerPage />
                        :
                        <AskAMuftiGridPage />
        
                    }
                </HeaderSliderWrapper>
        
          )
  }
  const SystemUserPortalWrapper = ()=>{
          const location = useLocation();
          const params = new URLSearchParams(location.search);
          const updateUser = params.get("updateUser");
          return (
                <HeaderSliderWrapper>
                    {
                        updateUser ?
                        <DetailViewSystemUser />
                        :
                        <SystemUserGridPage />
        
                    }
                </HeaderSliderWrapper>
        
          )
  }
  const BlogsPortalWrapper = ()=>{
          const location = useLocation();
          const params = new URLSearchParams(location.search);
          const editBlog = params.get("editBlog");
          return (
                <HeaderSliderWrapper>
                    {
                        editBlog ?
                        <BlogsEditor />
                        :
                        <BlogsUserGridPage />
        
                    }
                </HeaderSliderWrapper>
        
          )
  }
  const PendingBlogsPortalWrapper = ()=>{
          const location = useLocation();
          const params = new URLSearchParams(location.search);
          const editPendingBlog = params.get("editPendingBlog");
          return (
                <HeaderSliderWrapper>
                    {
                        editPendingBlog ?
                        <BlogsEditor />
                        :
                        <PendingBlogsGridPage />
        
                    }
                </HeaderSliderWrapper>
        
          )
  }


  return (
    <Router>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<ComonWrapperComponents><HomePage /></ComonWrapperComponents>} />
        <Route path="/about" element={<ComonWrapperComponents><AboutPage /></ComonWrapperComponents>} />
        <Route path="/ask-a-mufti" element={<ComonWrapperComponents><AskAMufti /></ComonWrapperComponents>} />
        <Route path="/services/zakat-fitrah" element={<ComonWrapperComponents><ZakatPage /></ComonWrapperComponents>} />
        <Route path="/services/zakat-fitrah" element={<ComonWrapperComponents><ZakatPage /></ComonWrapperComponents>} />
        <Route path="/services/nafil-sadaqa" element={<ComonWrapperComponents><NafilPage /></ComonWrapperComponents>} />


        <Route path="/services/khidmat-e-khalq" element={<ComonWrapperComponents><KhidmateKhalqPage /></ComonWrapperComponents>} />
        <Route path="/rashan" element={<ComonWrapperComponents><RashanPage /></ComonWrapperComponents>} />
        <Route path="/box" element={<ComonWrapperComponents><BoxPage /></ComonWrapperComponents>} />
        <Route path="/sadat" element={<ComonWrapperComponents><SadatPage /></ComonWrapperComponents>} />
        <Route path="/trust-expenses" element={<ComonWrapperComponents><TrustExpensesPage /></ComonWrapperComponents>} />



        <Route path="/services/qurbani" element={<ComonWrapperComponents><QurbaniPage /></ComonWrapperComponents>} />
        <Route path="/services/education" element={<ComonWrapperComponents><EducationPage /></ComonWrapperComponents>} />
        <Route path="/services/global-relief" element={<ComonWrapperComponents><GlobalReliefPage /></ComonWrapperComponents>} />
        <Route path="/services/islamic-event" element={<ComonWrapperComponents><MehfilPage /></ComonWrapperComponents>} />
        <Route path="/services/khatm-e-nabuwat" element={<ComonWrapperComponents><KhatmeNabowatPage /></ComonWrapperComponents>} />
        <Route path="/services/construction-of-Mosque" element={<ComonWrapperComponents><MosquePage /></ComonWrapperComponents>} />
        <Route path="/services/sadaqa" element={<ComonWrapperComponents><SadaqaPage /></ComonWrapperComponents>} />
        <Route path="/services/cos" element={<ComonWrapperComponents><COSPage /></ComonWrapperComponents>} />
        <Route path="/services/raza-library" element={<ComonWrapperComponents><RazaLibraryPage /></ComonWrapperComponents>} />
        <Route path="/services/noor-education" element={<ComonWrapperComponents><NoorEducationPage /></ComonWrapperComponents>} />
        <Route path="/services/dar-ul-iffta-fareedia" element={<ComonWrapperComponents><DarulIfftaPage /></ComonWrapperComponents>} />
        <Route path="/services/rims" element={<ComonWrapperComponents><RIMSPage /></ComonWrapperComponents>} />
        <Route path="/services/balagh" element={<ComonWrapperComponents><BalaghPage /></ComonWrapperComponents>} /> 


        {
          servicesPageData.map((service)=>{
            return(
              <Route 
              path={`/services/${service?.url}`} 
              element={<ComonWrapperComponents>
                <GenralServicesInnerPage
                 heading={service?.heading}
                 paragraph={service?.para}
                 img={service?.sectionImage}
                 ayah={service?.ayah}
                />
                </ComonWrapperComponents>} 
              /> 
            )
          })
        }


        {/* khidmate khalq / RF Welfare */}

        <Route path="/services/guardian" element={<ComonWrapperComponents><GuardianPage /></ComonWrapperComponents>} />
        <Route path="/services/financial-aid" element={<ComonWrapperComponents><FinancialAidPage /></ComonWrapperComponents>} />
        <Route path="/services/plantation" element={<ComonWrapperComponents><PlantationPage /></ComonWrapperComponents>} />
        <Route path="/services/masjid-madarsa-construction" element={<ComonWrapperComponents><MasjidAndMadarsaConstruction /></ComonWrapperComponents>} />

        {/* End khidmate khalq / RF Welfare */}


        {/* {khidmate khalq / RF Disaster Aid } */}
        <Route path="/services/corona-relief" element={<ComonWrapperComponents><CoronaRelief /></ComonWrapperComponents>} />
        <Route path="/services/tharparkar" element={<ComonWrapperComponents><TharParkarPage /></ComonWrapperComponents>} />
        <Route path="/services/balochistan" element={<ComonWrapperComponents><BalochistanPage /></ComonWrapperComponents>} />
        {/* End khidmate khalq / RF Disaster Aid  */}

        {/* khidmate khalq / RF Food */}
        <Route path="/services/dastarkhowan-tharparker" element={<ComonWrapperComponents><DastarkhowaanTharparkarPage /></ComonWrapperComponents>} />
        <Route path="/services/rashan-distribute" element={<ComonWrapperComponents><RashanDistributePage /></ComonWrapperComponents>} />
        <Route path="/services/family-support" element={<ComonWrapperComponents><FamilySupportPage /></ComonWrapperComponents>} />
        {/* End khidmate khalq / RF Food */}

        {/* End khidmate khalq / RF Education */}
        <Route path="/services/children-orientation-system" element={<ComonWrapperComponents><ChildrenOrientationPage /></ComonWrapperComponents>} />
        <Route path="/services/islamic-history" element={<ComonWrapperComponents><IslamicHistroyPage /></ComonWrapperComponents>} />
        <Route path="/services/education-in-quran" element={<ComonWrapperComponents><QuranEducationPage /></ComonWrapperComponents>} />
        {/* End khidmate khalq / RF Education */}


        {/* End khidmate khalq / RF Health */}
        <Route path="/services/medicine" element={<ComonWrapperComponents><MedicinePage /></ComonWrapperComponents>} />
        <Route path="/services/opd" element={<ComonWrapperComponents><OPDPage /></ComonWrapperComponents>} />
        <Route path="/services/consultation-physician" element={<ComonWrapperComponents><ConsultationPhysician /></ComonWrapperComponents>} />
        <Route path="/services/consultation-specialist" element={<ComonWrapperComponents><ConsultationSpecialistPage /></ComonWrapperComponents>} />
        {/* End khidmate khalq / RF Health */}


        {/* End khidmate khalq / RF Ehsaas */}
        <Route path="/services/khana-delhi" element={<ComonWrapperComponents><KhanaDelhiPage /></ComonWrapperComponents>} />
        <Route path="/services/langur-khana" element={<ComonWrapperComponents><LangurKhana /></ComonWrapperComponents>} />
        {/* End khidmate khalq / RF Ehsaas */}


        <Route path="/utilities" element={<ComonWrapperComponents><UtilitiesPage /></ComonWrapperComponents>} />
        <Route path="/grocery" element={<ComonWrapperComponents><GroceryPage /></ComonWrapperComponents>} />
        <Route path="/education-fees" element={<ComonWrapperComponents><EducationFeesPage /></ComonWrapperComponents>} />
        <Route path="/wedding" element={<ComonWrapperComponents><WeddingPage /></ComonWrapperComponents>} />
        <Route path="/business" element={<ComonWrapperComponents><BusinessPage /></ComonWrapperComponents>} />
        <Route path="/business" element={<ComonWrapperComponents><BusinessPage /></ComonWrapperComponents>} />



        <Route path="/services/hajj" element={<ComonWrapperComponents><HajjPage /></ComonWrapperComponents>} />
        <Route path="/ads" element={<ComonWrapperComponents><GoogleAds /></ComonWrapperComponents>} />
        <Route path="/blogs" element={<ComonWrapperComponents><Blogs /></ComonWrapperComponents>} />
        <Route path="/blog/:id" element={<ComonWrapperComponents><BlogInnerPage /></ComonWrapperComponents>} />
        {/* <Route path="/blog/:id" element={<BlogInnerPage />} /> */}
        <Route path="/donations" element={<ComonWrapperComponents><DonationPage /></ComonWrapperComponents>} />
        <Route path="/contact" element={<ComonWrapperComponents><ContactPage /></ComonWrapperComponents>} />
        <Route path="/gallery" element={<ComonWrapperComponents><GalleryPage /></ComonWrapperComponents>} />
        <Route path="/events" element={<ComonWrapperComponents><EventsPage /></ComonWrapperComponents>} />
        <Route path="/event-inner" element={<ComonWrapperComponents><EventsInnerPage /></ComonWrapperComponents>} />
        <Route path="/videos" element={<ComonWrapperComponents><VideosPage /></ComonWrapperComponents>} />
        <Route path="/privacy-policy" element={<ComonWrapperComponents><PrivacyPolicyPage /></ComonWrapperComponents>} />
        <Route path="/refund-policy" element={<ComonWrapperComponents><RefundPolicyPage /></ComonWrapperComponents>} />

        {/* Admin routes */}

        <Route path="/portal" element={<HeaderSliderWraper />} />
        <Route path="/portal/subscribe-user" element={<HeaderSliderWraper><SubscribedUserGridPage /></HeaderSliderWraper>} />
        <Route path="/portal/donation-users" element={<HeaderSliderWraper><DonationUserGridPage /></HeaderSliderWraper>} />
        <Route path="/portal/services-users" element={<HeaderSliderWraper><ServicesUserGridPage /></HeaderSliderWraper>} />
        <Route path="/portal/all-blogs" element={<BlogsPortalWrapper/>} />
        {/* <Route path="/portal/all-blogs" element={<HeaderSliderWraper><BlogsUserGridPage /></HeaderSliderWraper>} />
        <Route path="/portal/blog-editor" element={<HeaderSliderWraper><BlogsEditor /></HeaderSliderWraper>} /> */}
        <Route path="/portal/pending-blogs" element={<PendingBlogsPortalWrapper/>} />
        <Route path="/portal/system-users" element={<SystemUserPortalWrapper/>} />
        {/* <Route path="/portal/system-users" element={<HeaderSliderWraper><SystemUserGridPage /></HeaderSliderWraper>} /> */}
        {/* <Route path="/portal/system-detail-view" element={<HeaderSliderWraper><DetailViewSystemUser /></HeaderSliderWraper>} /> */}
        {/* <Route path="/portal/system-users" element={<HeaderSliderWraper><SystemExamsDetailView /></HeaderSliderWraper>} /> */}
        <Route path="/portal/ask-a-mufti" element={<AskAMuftiWrapper/>} />
        {/* <Route path="/portal/ask-a-mufti?answer=true" element={<HeaderSliderWraper><AskaMuftiAnswerPage /></HeaderSliderWraper>} /> */}

      </Routes>
    </Router>
  );
}

export default App; 