import React, { useState } from "react";
import "./LoginSignup.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { login } from "../../store/actions/userActions"
import FieldsRenderer from "../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import { encryptPassword, validateForm } from "../UI Helpers/HelperFunctions/Helper";

const LoginSignup = ({ closeModal, openSignup }) => {
  const [formInputData, setFormData] = useState("")
  const [saveBtn, setSaveBtn] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const inputsFieldAr = [
    {
      label: "Email",
      className: "col-lg-12",
      inputType: "text",
      name: "email",
      id: "email",
      required: true
    },
    {
      label: "Password",
      className: "col-lg-12",
      inputType: "password",
      name: "password",
      id: "password",
      required: true
    },
  ];

  return (
    <div className="container">
      <div className={`row form-login no-gutters`}>
        <div className="col-lg-6 login-popup-section">
          <div className="row">
            <div className="col-12">
              <div className="img-modal text-end d-block d-lg-none">
                <img onClick={closeModal}
                  src="/images/icons/icon-cross.png"
                  alt="cross-icon"
                  class="icon-cross"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="login-popup-box">
                <h2 className="h1_main">Login</h2>
                <p className="para_main">
                  Login if you already have an account
                </p>
                {/* <div className="social-login">
                  <img src="/images/icons/login-page-fb-icon.svg"></img>
                  <img src="/images/icons/login-page-google-icon.svg"></img>
                  <img src="/images/icons/login-page-linkedln-icon.svg"></img>
                </div> */}

                <form onSubmit={async (e) => {
                  e.preventDefault();
                  setSaveBtn(true)
                  let validationResponse = validateForm(inputsFieldAr, formInputData);
                  if (!validationResponse) {
                    setSaveBtn(false)
                    return
                  }
                  try {
                    await dispatch(login({ email: formInputData.email, password: encryptPassword(formInputData.password) }));
                    setSaveBtn(false)
                    closeModal();
                    navigate("/")
                  }
                  catch (e) {
                    setSaveBtn(false)
                  }
                }}
                >
                  <div className="form-group">
                    <FieldsRenderer
                      fieldsArray={inputsFieldAr}
                      updateState={setFormData}
                      currentState={formInputData}
                    />

                  </div>
                  <button type="submit" className="connect-button w-100" disabled={saveBtn} onClick={closeModal}>
                    <div><span> Login </span> {saveBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 signup-popup-section d-none d-lg-flex">
          <div className="signup-popup-box">
            <h2>Hello Friends!</h2>
            <p>
              Welcome to Raza Foundation, we are committed to making a meaningful impact through your donations.
            </p>
            <button className="btn btn-outline-light" onClick={openSignup}>Sign Up</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
