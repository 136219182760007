import React, { useEffect, useState } from "react";
import FieldsRenderer from "../components/UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import { showAlertMessage } from "../components/MasterComponents/CommonFunctionalities";
import { validateForm } from "../components/UI Helpers/HelperFunctions/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import CommonCalls from "../components/CommonAPICalls/CommonCalls";

const apiCall = new CommonCalls();

const AskaMuftiAnswerPage = () => {
    const [saveBtn, setSaveBtn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formInputData, setFormInputData] = useState("");

    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const questionId = params.get("questionid");

    
    useEffect(() => {
        const getData = async () => {

            setLoading(true);
            if (questionId) {
                setLoading(false);
            } else {
                showAlertMessage("Could not Find Question", "fail");
                navigate("/portal");
            }
        }
        getData();
    }, [questionId]);

    const inputsFieldAr = [
        {
            label: "Answer",
            className: "col-lg-12",
            inputType: "textarea",
            name: "answer",
            id: "answer",
            required: true,
        },
        
    ];

    const updateUserData = async () => {
        setSaveBtn(true);
        let validationResponse = validateForm(inputsFieldAr, formInputData);
        if (!validationResponse) {
            setSaveBtn(false);
            return;
        }
        const response = await apiCall.answerAQuestion(questionId, formInputData);
        if (response?.status === 202) {
            setSaveBtn(false);
            showAlertMessage(response?.message, "success");
            return response;
        } else {
            setSaveBtn(false);
            showAlertMessage(response?.message, "fail");
            return;
        }
    };
    return (
        <>
             <div className="user-detail-view-container container-fluid ps-4 pe-4 py-4">
               
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            setSaveBtn(true);
                            updateUserData();
                        }}
                    >
                        <div className="w-100 text-end ">
                            <button
                                type="submit"
                                className="connect-button w-fit p-3 py-2"
                                disabled={saveBtn}
                            >
                                <div>
                                    <span> Save </span>{" "}
                                    {saveBtn ? (
                                        <output
                                            className="spinner-border spinner-border-sm ms-1"
                                            aria-hidden="true"
                                        ></output>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </button>
                        </div>
                        <div className="d-flex flex-column  w-100 pt-4">
                            <p className="mb-2" style={{color: "#1972B7",fontWeight: "500",fontSize: "16px"}}>Question:</p>
                            <p className="body-paragraph ps-3">{sessionStorage.getItem("portalCurrentQuestion")}</p>
                        </div>
                        <FieldsRenderer
                            fieldsArray={inputsFieldAr}
                            updateState={setFormInputData}
                            currentState={formInputData}
                        />
                    </form>
                </div>
        </>
    );
};

export default AskaMuftiAnswerPage; 