import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../components/UI Helpers/DataGrid/CustomDataGrid";
import Settings from "../components/CommonAPICalls/Settings";
import CommonCalls from "../components/CommonAPICalls/CommonCalls";
import { gridLinkValueFormatter, showAlertMessage, checkIfArrayIsEmpty } from '../components/MasterComponents/CommonFunctionalities';

const AskAMuftiGridPage = () => {
  const [questionData, setQuestionData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const apiCalls = new CommonCalls();

  const navigate = useNavigate();

  useEffect(() => {
    getAllQuesions();
  }, [pageNumber, pageSize]);

  const getAllQuesions = async () => {
    setLoading(true);
    try {
      const apiResponse = await apiCalls.getAllQuestions(pageNumber, pageSize, false);
      if (apiResponse) {
        setQuestionData(apiResponse?.data);
        setTotalRecords(apiResponse?.total_records);
        setLoading(false);
      }
    } catch (err) {
        setQuestionData([]);
        setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onEmailClicked = (row, colKey) => {
    if (colKey === 'question') {
      sessionStorage.setItem("portalCurrentQuestion", row?.question);
      navigate(`/portal/ask-a-mufti?answer=true&questionid=${row?.user_question_id}`);
    }
  };

  

  const gridColumnHeaders = [
    { field: 'question', columnName: 'Question',cellRenderer: gridLinkValueFormatter,customRendererLink: true, },
    { field: 'answer', columnName: 'Answer' },

  ];

  return (
    <div className='listings_view_wrapper' style={{ padding: "20px" }}>
      <h1 className="h1_main mt-2">Ask a Mufti Questions:</h1>
      <p className="para_main">Click on a question to answer it.</p>

      <CustomDataGrid
        columns={gridColumnHeaders}
        data={questionData}
        pageSize={pageSize}
        loadingState={loading}
        hideAddBtn={true}
        pagination={true}
        onCellClicked={onEmailClicked}
        totalRecords={totalRecords}
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={setPageSize}
        searchField={true}
        hideDeleteBtn={true}
        hideExcelBtn={false}
        excelFileBtnType={"AskAMufit"}
        excelFn={apiCalls.getAllQuestions}
        excelArgument={[1,totalRecords,false]}

      />
    </div>
  );
};

export default AskAMuftiGridPage; 